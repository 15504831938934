import React from "react";
import Vimeo from "@u-wave/react-vimeo";

import Layout from "../components/layout";

const Discovery = () => (
  <Layout title="Our Discovery">
    <Vimeo
      video={508_545_521}
      showByline={false}
      showPortrait={false}
      showTitle={false}
      responsive
    />
  </Layout>
);

export default Discovery;
